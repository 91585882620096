<template>
  <div v-if="!isFormSubmitted">
    <form @submit.prevent="handleSubmit">
      <span v-if="title" class="form-title">Newsletters</span>
      <span>Soyez informé en avant-première de tous nos voyages et de tous nos circuits</span>
      <div class="form-group">
        <label for="civility">Civilité *</label>
        <select
          id="civility"
          v-model="formFields.civility"
          :class="{ 'input-error': hasError('civility') }"
        >
          <option value="" disabled>Choisir...</option>
          <option value="Mr.">Monsieur</option>
          <option value="Ms.">Madame</option>
        </select>
        <span v-if="formErrors.civility" class="error">{{ formErrors.civility }}</span>
      </div>
      <div class="form-group">
        <label for="firstName">Prénom *</label>
        <input
          id="firstName"
          v-model="formFields.firstName"
          type="text"
          :class="{ 'input-error': hasError('firstName') }"
        />
        <span v-if="formErrors.firstName" class="error">{{ formErrors.firstName }}</span>
      </div>
      <div class="form-group">
        <label for="lastName">Nom *</label>
        <input
          id="lastName"
          v-model="formFields.lastName"
          type="text"
          :class="{ 'input-error': hasError('lastName') }"
        />
        <span v-if="formErrors.lastName" class="error">{{ formErrors.lastName }}</span>
      </div>
      <div class="form-group">
        <label for="zipCode">Code Postal</label>
        <input
          id="zipCode"
          v-model="formFields.zipCode"
          type="text"
          :class="{ 'input-error': hasError('zipCode') }"
        />
        <span v-if="formErrors.zipCode" class="error">{{ formErrors.zipCode }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email *</label>
        <input
          id="email"
          v-model="formFields.email"
          type="email"
          :class="{ 'input-error': hasError('email') }"
        />
        <span v-if="formErrors.email" class="error">{{ formErrors.email }}</span>
      </div>
      <button>Je m'inscris</button>
    </form>
  </div>
  <div v-else>
    <div class="form-validation">
      <Icon name="check-circle" height="54px" width="54px" stroke="var(--color-green)" />
      <p class="form-validation-message">Merci de votre inscription</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { NewsletterFormModel } from '@/lib/types/models/newsletterForm';

const props = defineProps<{
  title?: boolean;
  email?: string;
}>();

const isFormSubmitted = ref(false);

const formFields = ref<NewsletterFormModel>({
  civility: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  email: ''
});

const formErrors = ref<NewsletterFormModel>({
  civility: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  email: ''
});

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/;
const zipCodeRegex = /^\d*$/;

const validateForm = () => {
  formErrors.value.civility = formFields.value.civility ? '' : 'La civilité est requise.';
  formErrors.value.firstName = nameRegex.test(formFields.value.firstName)
    ? ''
    : 'Le prénom est vide ou contient des caractères invalides.';
  formErrors.value.lastName = nameRegex.test(formFields.value.lastName)
    ? ''
    : 'Le nom est vide ou contient des caractères invalides.';
  formErrors.value.email = emailRegex.test(formFields.value.email) ? '' : "L'email est invalide.";
  formErrors.value.zipCode = zipCodeRegex.test(formFields.value.zipCode)
    ? ''
    : 'Le code postal est invalide.';

  return Object.values(formErrors.value).every(error => error === '');
};

const hasError = (fieldName: keyof NewsletterFormModel) => {
  return formErrors.value[fieldName] !== '';
};

const { $csrfFetch } = useNuxtApp();

const handleSubmit = () => {
  if (validateForm()) {
    isFormSubmitted.value = true;
    $csrfFetch('/api/newsletter/lead', {
      method: 'POST',
      body: formFields.value
    });
  }
};

watch(
  () => props.email,
  newEmail => {
    if (newEmail) {
      formFields.value.email = newEmail;
    }
  },
  { immediate: true }
);
</script>
<style scoped lang="scss">
@use '$/colors.scss';
@use '$/typography.scss';

.form-title {
  padding: 0.25rem;

  font-size: typography.$desktop-h4;
  font-weight: 600;
  color: white;
  text-align: center;
  text-transform: uppercase;

  background-color: colors.$green;
}

.form-validation {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  .form-validation-message {
    padding: 0.25rem 0.5rem;

    font-size: typography.$desktop-h4;
    font-weight: 600;
    color: white;
    text-align: center;
    text-transform: uppercase;

    background-color: colors.$green;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: colors.$black;
}

.form-group {
  display: flex;
  flex-direction: column;

  .input-error {
    border: 1px solid colors.$red;
  }

  .error {
    color: colors.$red;
  }
}

button {
  cursor: pointer;
  padding: 0.5rem;
  color: white;
  background-color: colors.$green;
}
</style>
